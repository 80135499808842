.header {
  text-align: center;
}


/* Grid-related CSS */

:root {
  --square-size: 6px;
  --square-gap: 2px;
  --week-width: calc(var(--square-size) + var(--square-gap));
}

.months { grid-area: months; }
.days { grid-area: days; }
.squares { grid-area: squares; }

.graph {
  display: inline-grid;
  grid-template-areas: "empty months"
                       "days squares";
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
}

.months {
  list-style-type: none;
  display: grid;
  grid-template-columns: calc(var(--week-width) * 4) /* Jan */
                         calc(var(--week-width) * 4) /* Feb */
                         calc(var(--week-width) * 4) /* Mar */
                         calc(var(--week-width) * 5) /* Apr */
                         calc(var(--week-width) * 4) /* May */
                         calc(var(--week-width) * 4) /* Jun */
                         calc(var(--week-width) * 5) /* Jul */
                         calc(var(--week-width) * 4) /* Aug */
                         calc(var(--week-width) * 4) /* Sep */
                         calc(var(--week-width) * 5) /* Oct */
                         calc(var(--week-width) * 4) /* Nov */
                         calc(var(--week-width) * 5) /* Dec */;
}

.days,
.squares {
  list-style-type: none;
  display: grid;
  grid-gap: var(--square-gap);
  grid-template-rows: repeat(7, var(--square-size));
}

.squares {
  grid-auto-flow: column;
  grid-auto-columns: var(--square-size);
}

.graph {
  padding: 0px;
  margin: 0px;
}

.days li:nth-child(odd) {
  visibility: hidden;
}

.squares li {
  background-color: #ebedf0;
}

.squares li[data-level="1"] {
  background-color: #c6e48b;
}

.squares li[data-level="2"] {
  background-color: #7bc96f;
}

.squares li[data-level="3"] {
  background-color: #196127;
}